<div
  class="-z-50 w-screen bg-gray-100 shadow-inner p-2 md:p-6 rounded-b-lg mb-4"
>
  <div class="border-b border-b-gray-200 pb-2">
    <h1 class="text-gray-500 font-bold md:text-lg lg:text-xl mt-2 mb-2">
      {{
      "main-page.services.service-description" | translate
      }}
    </h1>
    <p class="text-justify text-sm md:text-base">
      {{ service.description ? service.description : "-" }}
    </p>
  </div>
  <div class="border-b border-b-gray-200 pb-2" *ngIf="filterInputs.length !== 0">
    <h1 class="text-gray-500 font-bold md:text-lg lg:text-xl mt-2 mb-2">
      {{"main-page.services.service-parameters" | translate }}
    </h1>
    <div class="grid grid-cols-1 sm:grid-cols-4">
    <div *ngFor="let filterInput of filterInputs">
      <div class="flex gap-0.5 xl:gap-2 flex-col lg:flex-row items-start sm:items-center">
        <p class="text-secondary-500 font-bold text-sm xl:text-base">{{filterInput.identifier + ": "}}</p>
        <p  class="text-gray-700 text-sm xl:text-base p-1">{{filterInput.value}}</p>
      </div>
    </div>
    </div>
  </div>
  <div>
    <ng-container *ngIf="!isLoading; else isLoadingTemplate">
      <ng-container
        *ngIf="
          orderJobs.length > 0 && orderHasOutputs;
          else noAvailableDownload
        "
      >
        <div class="flex justify-between py-4">
          <div>
            <h1 class="text-gray-500 font-bold md:text-lg lg:text-xl mb-0">
              {{
              "main-page.orders.order-outputs" | translate
              }}
            </h1>
          </div>
            <div class="flex flex-col items-center">
              <app-button
                (btnClick)="downloadAll(orderId)"
                [isSecondary]="true"
                [label]="'actions.downloadAll'| translate "
                ></app-button>
                <div *ngIf="!isShowHelp">
                  <a (click)="isShowHelp = !isShowHelp"  class="align-self-end text-primary-500 underline cursor-pointer">{{'helper-texts.showHelp' | translate}}</a>
                </div>
                <div *ngIf="isShowHelp">
                  <a (click)="!isShowHelp = !isShowHelp" *ngIf="!isShowHelp===!isShowHelp" class="align-self-end text-primary-500 underline cursor-pointer">{{'helper-texts.hideHelp' | translate}}</a>
                </div>       
            </div>
        </div>
        <div *ngIf="isShowHelp" class="border-b border-b-gray-200 pb-2 mb-2">
          <p class="font-bold text-secondary-500"> {{ "helper-texts.downloadAll.title" | translate }}</p>
          <ul class="list-disc list-inside">
            <li>{{ "helper-texts.downloadAll.click" | translate }}</li>
            <li>{{ "helper-texts.downloadAll.install.text" | translate }} 
              <a [href]="env.downloadAllHelp.url" target="_blank" class="underline text-secondary-500">
              {{"helper-texts.downloadAll.install.linkText" | translate}}
              </a>
            </li>
            <li>{{ "helper-texts.downloadAll.follow" | translate }}</li>
          </ul>
        </div>
        <ng-container *ngFor="let job of orderJobs; index as jobId">
          <div>
            <div class="flex items-center gap-4">
              <app-order-property-item
                      [label]="'labels.status'|translate"
                      [value]="job.status"
                      [blink]="true"
              ></app-order-property-item>
              <app-order-property-item
                      [label]="'labels.created-at' | translate"
                      [value]="serializeDate(job.createdAt.toString())"
              ></app-order-property-item>
            </div>
            <div *ngIf="hasWMSOutput(job.outputs) && env.geoportalUrl !== ''" class="flex flex-col gap-2 justify-left py-4">
              <p>{{ 'main-page.orders.geoportal.notice1' | translate }}</p>
              <p>{{ 'main-page.orders.geoportal.notice2' | translate }}
                <a class="hover:cursor-pointer" (click)="redirectTo(service.identifier, orderId)" target="_blank">
                  <b>
                    <u>Geoportal </u> <fa-icon [icon]="faExternalLinkAlt"></fa-icon>
                  </b>
                </a>
                {{ 'main-page.orders.geoportal.notice3' | translate }}
              </p>
              <div class="px-4">
                <p>{{ 'main-page.orders.geoportal.steps.1' | translate }}</p>
                <p>{{ 'main-page.orders.geoportal.steps.2' | translate }} <b>{{ service.title }}</b></p>
                <p>{{ 'main-page.orders.geoportal.steps.3' | translate }} <b>{{ orderId }}</b></p>
                <p>{{ 'main-page.orders.geoportal.steps.4' | translate }} </p>
              </div>
               
            </div>
            <ng-container *ngIf="job.outputs && job.outputs.length > 0">
              <h1 class="text-gray-500 font-bold md:text-lg lg:text-xl mt-4">
                {{
                "main-page.orders.available-downloads" | translate
                }}
              </h1>
              <ng-container *ngFor="let output of getFilteredOutputs(job.outputs); index as outputIdx" >
                <ng-container
                  *ngFor="let layerKey of objectKeys(output.layers), index as layerIdx">
                  <ng-container
                    *ngFor="
                    let layer of output.layers[layerKey];
                  "
                  
                  >
                    <div
                      class="
                      flex
                      justify-between
                      items-start
                      gap-4
                      mb-4
                      border-b
                      pt-4
                      
                      
                      
                    "
                    >
                    <div class="flex justify-start gap-4 md:max-w-3xl lg:w-full lg:gap-6">
                      <app-order-property-item [label]="'labels.file'| translate" [value]="output.productIdentifier">
                      </app-order-property-item>
                      <app-order-property-item [label]="'labels.layer'| translate" value="{{ layer.title }}">
                      </app-order-property-item>
                    </div>
                      <div *ngIf="!isTimeSeries(output) || layer.timestamps === null" class="flex flex-row gap-4">
                        
                        <app-button
                          [label]="'actions.view-metadata'| translate"
                          (btnClick)="onViewMetadata(layer, outputIdx, jobId, layerIdx, layerKey)"
                          [rightIcon]="(isMetadataLoading &&
                            isMetadataButtonClicked(outputIdx, jobId, layerIdx, layerKey)) ? 
                            faSpinner : undefined"
                          iconClasses="animate-spin"
                          [isDisabled]="isMetadataLoading"
                        ></app-button>
                        
                        <a>
                          <app-button
                            (btnClick)="downloadFile(layer.downloadURI, layer.title, 
                              outputIdx, jobId, layerIdx, layerKey)"
                            [label]="'actions.download'| translate"
                            [isDisabled]="isDownloadingFile"
                            [rightIcon]="(isDownloadingFile 
                              && downloadButtonClicked(outputIdx, jobId, layerIdx, layerKey)) ? faSpinner : undefined"
                            [iconClasses]="isDownloadingFile ? 'animate-spin' : ''"
                          ></app-button>
                        </a>
                      </div> 
                      <div *ngIf="isTimeSeries(output) && layer.timestamps !== null" class=" flex justify-between gap-4">
                      
                        <div *ngIf="getMap(outputIdx,layerIdx,layerKey,jobId)" class="flex flex-col pb-2 align-center max-h-80 overflow-scroll">
                        
                          <div *ngFor="let timeStamp of layer.timestamps; let indexTime = index" class="flex flex-row pb-2 ">
                            <p class="self-center pr-2">{{timeStamp}}</p>
                            <a [href]="specificOrder(layer.downloadURI,timeStamp)" target="_blank">
                              <app-button [label]="'actions.download'| translate"> </app-button>
                            </a>
                          </div>
                      
                        </div>
                        <app-button
                          (btnClick)="setMap(outputIdx,layerIdx,layerKey,jobId)"
                          [isDark]="getMap(outputIdx,layerIdx,layerKey,jobId)"
                          [isSecondary]="true"
                          [label]="'actions.download' | translate"
                          [rightIcon]="getMap(outputIdx,layerIdx,layerKey,jobId) ? chevronUp : chevronDown"
                          class="pb-2"
                        ></app-button>
                        
                      </div>

                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-template #noAvailableDownload>
      <div *ngIf="orderHasAllOutputs" class="flex items-center bg-blue-500 text-white text-sm font-bold px-4 py-3" role="alert">
        <fa-icon [icon]="faInfoCircle" class="w-4 h-4 mr-2"></fa-icon>
        <p [ngSwitch]="orderStatus">
          <span *ngSwitchCase="'created'">
            Order created. Wait until it is fully proccessed.
          </span>
          <span *ngSwitchCase="'pending'">
            Your order is still pending. Wait until it is fully proccessed.
          </span>
          <span *ngSwitchCase="'finished'">
            Your order is finished. Wait until outputs are catalogued and delivered.
          </span>
        </p>
      </div>
      <p class="text-gray-500 font-bold mt-2">{{ 'main-page.orders.no-outputs' | translate }}</p>
      <span *ngIf="!isSubscription || (isSubscription && orderStatus === 'FINISHED')" class="text-gray-500">{{ 'main-page.orders.no-outputs-message' | translate }}</span>
      <span *ngIf="isSubscription && orderStatus === 'SUBSCRIPTION_ACTIVE'" class="text-gray-500">{{ 'main-page.orders.no-outputs-subscription' | translate }}</span>
    </ng-template>
    <ng-template #isLoadingTemplate>
      <p class="text-gray-500 animate-pulse font-bold mt-2">{{ 'main-page.orders.loading' | translate }}</p>
      <span class="text-gray-500">{{ 'main-page.orders.outputsTime' | translate }}</span>
    </ng-template>
  </div>
</div>

