import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpStatusCode
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LoadingService, SnackbarService } from '../../services';

import { handleError } from '@core/configs';
import { getPropsInObject, isStringEmpty, TruncatePipe } from 'shared';
import { environment } from '../../../../environments';

/**
 * This class is for intercepting http requests. When a request starts, we set the loadingSub property
 * in the LoadingService to true. Once the request completes and we have a response, set the loadingSub
 * property to false. If an error occurs while servicing the request, set the loadingSub property to false.
 * @class {LoadingInterceptor}
 */
@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  truncate: any;

  statusMap: {[x: string]: string} = {
    '400': 'Bad Request',
    '401': 'Unauthorized',
    '404': 'Not Found',
    '500': 'Server Error'
  }

  constructor(
    private loadingService: LoadingService,
    private snackbarService: SnackbarService
  ) {
    this.truncate = new TruncatePipe();
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    this.loadingService.setLoading(true, request.url);
    return next
      .handle(request)
      .pipe(
        catchError((err: HttpErrorResponse) => {

          if (err instanceof HttpErrorResponse && err.error instanceof Blob && err.error.type === "application/json") {
            let reader = new FileReader();

            reader.onload = (e: Event) => {
              const errmsg = JSON.parse((<any>e.target).result);
              this.snackbarService.danger(this.statusMap[err.status] ?? err.statusText, errmsg).during(5000).show();
            }

            reader.readAsText(err.error);
            
          } else {

            const { error } = err;
          
            const messageProp = getPropsInObject(error, 'message');
            const statusProps = getPropsInObject(error, 'status');
  
            const message = isStringEmpty(messageProp)
              ? 'An unrecognized error occurred.'
              : messageProp;
            const status: string = isStringEmpty(statusProps.toString())
              ? HttpStatusCode.InternalServerError
              : statusProps.toString();
  
            this.loadingService.setLoading(false, request.url);
  
            const truncate = this.truncate.transform(message ?? error.error, 255);
            if (request.url.includes(environment.apiUrl) || request.url.includes(environment.storageUrl)) {
  
              // HAMMER TIME: prevent 404 popup for not found outputs
              if (!(request.url.includes('outputs') && status === '404')) {
                this.snackbarService.danger(this.statusMap[status] ?? status, truncate).during(5000).show();
              }
            }
          }

          throw handleError(err);
        })
      )
      .pipe(
        map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
          if (evt instanceof HttpResponse) {
            this.loadingService.setLoading(false, request.url);
          }
          return evt;
        })
      );
  }
}
